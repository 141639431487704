@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&family=IBM+Plex+Sans+Arabic:wght@200&display=swap%27');

.allTeamContainer{
    background-color: var(--ebb_white);
    padding: 5%;
    padding-top: 3.5%;
    width: 100%;
}

.allTeamContainer h1{
    color: var(--porter_black);
    font-size: 75px;
    border-top: 8px solid var(--porter_black);
    padding-top: 20px;
	margin-bottom: 20px;
    font-family: var(--title_font);
}

.allTeamContainer hr{
    width: 60%;
    color: var(--porter_black);
    height: 5px;
    margin-bottom: 40px;
    background-color: var(--porter_black);
    border: none;
}
.allTeam {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageCard{
    justify-content: center;
}
.Team_img{   
    
    /* height: 300px;  */
    width: 90%;
    object-fit: cover;
    object-position: 0 0;
}

.Team_Grid{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.Team_Name{
    margin-top: 20px;
    font-size: 35px;
    color: var(--porter_black);
    font-family: 'DM Sans', sans-serif;
    justify-content: left;
}
.Team_Title{
    padding-top: 15px;
    font-size: 25px;
    color: var(--porter_black);
    font-family: 'IBM Plex Sans Arabic', sans-serif;
}
.teamBioLink{
    font-size: 25px;
    color: var(--porter_black);
    font-family: var(--text_font);
}

