.carousel {
	position: absolute;
	width: max(579px, 30%);
	line-height: 25px;
}
.carouselArrows {
	position: absolute;
	top: -6%;
	right: 2%;
}
.arrows {
	margin: 0 15px;
	width: 30px;
}

.carouselProjectCard {
	display: flex;
	flex-direction: column;
	color: var(--ebb_white);
	width: 100%;
	height: 550px;
}

.carouselPadding {
	padding: 10px;
}

.LowerLineBreak {
	margin: 5px;
	padding: 2px;
	background-color: var(--ebb_white);
}
.headLineBreak {
	margin: 10px;
	padding: 4px;
	background-color: var(--ebb_white);
}
.projectDescription{
	margin: 10px;
	font-size: 20px;
}
.projectTitle {
	display: flex;
}
.projectName {
	font-size: 32px;
	color: var(--ebb_white);
}
.projectLocation {
	align-self: flex-end;
	font-size: 20px;
	margin-left: 30px;
	color: var(--ebb_white);
}
.projectLink {
	display: flex;
	padding-left: 30px;
}
.projectLink span{
	padding-left: 60px;
	font-size: 24px;
}
.projectLink img {
	width: 100%;
	height: 100px;
	min-width: 150px;
}
.arrow {
	justify-content: center;
	align-items: center;
	color: var(--red_orange);
}
.arrowLink {
	padding: 30px;
}
@media screen and (min-width: 2185px) {
	.carousel {
		margin-top: 10%;
	}
}
@media screen and (max-width: 1675px) {
    .carousel {
		top: 150%;
		width: 50%;
	}
}
@media screen and (max-width: 1360px) {	
    .carousel {
		width: 60%;
	}
}
@media screen and (max-width: 880px) {	
    .carousel {
		position: static;
		width: 100%;
	}
	.projectName {
		font-size: 17px;
		font-weight: 700;
	}
	.projectLocation {
		margin-left: 15px;
		font-size: 15px;
	}
	.projectDescription{
		font-size: 16px;
	}
	.carouselProjectCard {
		height: 470px;
	}
}
@media screen and (max-width: 560px) {	
    .arrowLink{
		display:none;
	}
}
@media screen and (max-width: 405px) {	
    .projectLink span{
		padding-left: 30px;
		font-size: 20px;
	}
}
