

.Team_Section {
    background-color: var(--ebb_white);
    color:var(--porter_black);
	width: 100vw;
	height: calc(100vh - 130px);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.Team_Section hr{
    color:var(--porter_black);
	margin: 0 5%;
    height: 8px;
	width: 100%;
    background-color: var(--porter_black);
}
.Team_Section .memberDescSection hr{
    color:var(--red_orange);
    height: 5px;
	margin: 5px;
	width: 100%;
    background-color: var(--red_orange);
}

.memberContainer {
	display: flex;
	justify-content: center;
	padding-right: 5%;
	padding-left: 5%;
}

/* business name, location and description */
.memberDescSection {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
    /* justify-content: space-between; */
    padding-bottom: 3%;
}
.memberDescSection {
	padding-left: 30px;
    display: flex;
	flex-direction: column;
}
.memberDescription{
    margin: 10px;
}
.memberDescription span{
    margin: 10px;
	font-weight: 800;
}
.memberName{
	padding-left: 5px;
    font-size: 40px;
}
.memberDescription{
	padding: 5px;
	text-align: justify;
	text-justify: auto;
    line-height: 25px;
}

/* images */
.memberImgSection {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	
	/* height: 85vh; */
	/* margin-right: 5%; */
}

.memberMainImg {
	width: 50vw;
	max-width: 729px;
	padding: 30px 0px 30px 0px;
	object-fit: contain;
}




@media screen and (max-width: 1260px) {
	.Team_Section {
		height: 100%;
	}
	.memberContainer {
		flex-wrap: wrap;
	}
	.memberImgSection img{
		width: 100%;
		order: 1;
	}
	.memberContainer {
		margin: 0 5%  0 0;
		order: 2;
	}
	.Team_Section hr{
		width: 95%;
	}
	.memberDescSection{
		padding-left: 0px;
	}
}