.servicesContainer {
    padding: 50px 5%;
    background-color: var(--porter_black);
}

.servicesContainer h2 {
    color: var(--ebb_white);
    font-family: var(--title_font);
    font-size: 75px;
	border-top: 8px solid var(--red_orange);
	margin-bottom: 20px;
	padding-top: 20px;
    font-weight: 900;
}

.servicesContainer hr {
    width: 60%;
    color: var(--red_orange);
    height: 5px;
    margin-bottom:20px;
    background-color: var(--red_orange);
    border: none;
}

.allServices {
	display: flex;
	justify-content: space-evenly;
    flex-flow: row wrap;
	background-color: var(--porter_black);
    align-content: space-around;
}

.serviceCard {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 20px ;
    min-width: 300px;
    max-width:520px;
}

.serviceTitle {
    color: var(--ebb_white);
    font-size: 34px;
    height: 74px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.serviceDescription{
    border-top: 3px solid var(--red_orange);
    color: var(--ebb_white);
    line-height: 30px;
    font-size: 22px;
    padding-top: 10px;
}

@media screen and (max-width:465px){
    .servicesContainer h2 {
        font-size: 45px;
    }
}