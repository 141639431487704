* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  /* Primary colors */
  --porter_black:#161616 ;
  --red_orange: #ff4622  ;
  --warm_gray: #a09f9f;
  --ebb_white: #eeeaea;
/* Secondary colors */
  --picton_blue: #24a1ff;
  --sun_yellow: #ffc943;
  /* Fonts */
  --title_font: 'Gilroy_Black';
  --text_font:'Proxima Nova Light' ;
}
html {
  height: 100%;
}

body {
  min-height: 100%;
  line-height: 1;
  font-family: 'Proxima Nova Light', sans-serif; 
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
}

input, select, option, optgroup, textarea, button,
pre, code {
  font-size: 100%;
  font-family: inherit;
}

ol, ul {
  list-style: none;
}

a{
  text-decoration: none;
}

@font-face {
  font-family: 'Gilroy_Black';
  src: url('../fonts/Gilroy-Black.otf') format('opentype');
}

