.aboutUsPage {
	background-color: var(--ebb_white);
	padding: 50px 5%;
}

.aboutUsPage h2 {
	color: var(--porter_black);
	font-family: var(--title_font);
	font-size: 75px;
	font-weight: 900;
	border-top: 8px solid var(--porter_black);
	margin-bottom: 20px;
	padding-top: 20px;
}
.aboutUsPage hr {
    width: 60%;
    color: var(--porter_black);
    height: 5px;
	margin-bottom:20px;
    background-color: var(--porter_black);
}
.aboutContainer {
	/* font-family: var(--text_font); */
	padding-top: 30px;
	line-height: 30px;
	font-size: 20px;
	display: flex;
}

.aboutContainer img {
	width: 68vw;
	object-fit: contain;
	margin-left: 40px;
}
.aboutContent p {
	margin-bottom: 30px;
}
@media screen and (max-width: 1620px) {
	.aboutContainer {
		flex-wrap: wrap;
	}
	.aboutContainer img {
		width: 100%;
		margin: 0 0 30px;
		order: 1;
	}
	.aboutContent {
		order: 2;
	}
}
@media screen and (max-width:465px){
    .aboutUsPage h2 {
        font-size: 45px;
    }
}