.homePage {
	height: calc(100vh - 133px);
	background-color: var(--porter_black);
}
.homeHeroContainer {
	width: 100vw;
}
.homeHeroImage {
	position: absolute;
	z-index: 0;
	width: calc(100vw - 25%);
	object-fit: contain;
	bottom: 0;
	right: 0;
}
.carouselPhone {
	display: none;
}
.homeHeroContent {
	padding: 10px 5%;
	position: absolute;
	top: 118px;
	z-index: 1;
}

.homeHeroContent h1 {
	padding-top:1%;
	font-size: 180px;
	font-weight: 700;
	font-family: var(--title_font);
	border-top: 10px solid var(--ebb_white);
	color: var(--ebb_white);
	width: 90vw;
}
.carouselContainer {
	position: relative;
	bottom: -5rem;
}

.downArrow {
	position: absolute;
	bottom: 60px;
	right: 50%;
	animation: 1s linear 1s infinite alternate animateDown;
}
@keyframes animateDown {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(20px);
	}
}

@media screen and (max-width: 1581px) {
	.homeHeroContent h1 {
		font-size: 100px;
		padding-top:30px;
	}
}
@media screen and (max-width: 1675px) {
	.downArrow {
		display: none;
	}
}

@media screen and (max-width: 880px) {
	.carouselContainer {
		display: none;
	}
	.homeHeroImage {
		width: 150%;
	}
	.carouselPhone {
		display: block;
		padding-top: 10px;
		background-color: var(--porter_black);
	}
}
@media screen and (max-width: 675px) {
	.homeHeroImage {
		position: relative;
		width: 100%;
		bottom: 0;
		left: 0;
		margin-top: 150px;
		/* clip-path: inset(5% 0 0px 40%); */
	}
	.homePage {
		height: 100%;
	}
}
@media screen and (max-width: 455px) {
	.homeHeroContent h1 {
		font-size: 65px;
	}
}
