.allProjectsContainer {
	padding: 50px 5%;
    background-color: var(--porter_black);
}
.allProjectsContainer h2 {
    color: var(--ebb_white);
    font-family: var(--title_font);
	font-size: 75px;
	border-top: 8px solid var(--red_orange);
	padding-top: 20px;
	margin-bottom: 20px;
    font-weight: 900;
}
.allProjectsContainer hr{
    width: 60%;
    color: var(--red_orange);
    height: 5px;
    margin-bottom: 40px;
    background-color: var(--red_orange);
    border: none;
}
.allProjects {
	display: flex;
	justify-content: center;
    flex-flow: row wrap;
	background-color: var(--porter_black);
    gap:40px;
}
.imageCard {
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--ebb_white);
    width: 520px;
    height: 515px;
}
.imageCard:hover {
   border: solid 5px var(--red_orange);
}
.projectTitle {
    color: black;
    font-size: 28px;
    margin:20px;
    line-height: 35px;
    font-weight: 600;
}
.projectTitleContainer{
    margin: auto;
    text-align: center;
}
.projectImg{
    width: 100%;
    height: 340px; 
    object-fit: cover;
}

@media screen and (max-width:760px){
    .allProjects {
        gap:0px;
        padding: 0;
    }
    .imageCard {
        margin-top: 30px;
        width: 420px;
        height: 415px;
    }
}

@media screen and (max-width:465px){
    .allProjectsContainer h2 {
        font-size: 45px;
    }
    .imageCard {
        width: 320px;
        height: 315px;
    }
    .projectTitle {
        color: black;
        font-size: 28px;
        margin: 20px;
        line-height: 35px;
        font-weight: 600;
    }
    .projectTitle {  
        font-size: 18px;
        margin: 20px;
        line-height: 25px;
    }
}