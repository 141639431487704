.contrastCard {
    background-color: var(--red_orange);
    padding: 20px 20px;
    margin: -20px 0 -20px -20px;
    color:var(--ebb_white);
    font-weight:600;
}

.textLinkFormat {
    display:flex;
    flex-direction: column;
    justify-self: flex-end;
    align-content: center;
    font-size: 20px;
    line-height: 25px;
}
.textLinkFormat h3{
    font-weight: 900;
    font-size: 34px;
    line-height: 36px;
    padding: 0 50px 30px 0;
}
.textLinkFormat p{
    padding: 0 60px 40px 0;
    height: 265px;
    min-width: 360px;
}

.textLinkFormat span{
    font-weight: 900;
    font-size: 22px;
    color: var(--red_orange);
}
.textLinkFormat span:hover{
    color: var(--porter_black);
}
.contrastCard > span{
    color: var(--porter_black);
    font-size: 22px;
}
.contrastCard > span:hover{
    color: var(--ebb_white);
}
.contrastCard p{
    height: 235px;
}

@media screen and (max-width: 1190px) {
    .textLinkFormat {
        margin-top: 30px;
    }
    .textLinkFormat h3{
        text-decoration-line: underline ;
        
    }
    .textLinkFormat p{
        min-width: 0px;
        width: 100%;
        height: 100%;
    }
    .contrastCard {
        padding: 20px;
    }
    /* .contrastCard h3{
        padding: 20px;
    }
    .contrastCard span{
        padding: 15px;
    } */
}
