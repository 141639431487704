.introTitle {
    font-family: var(--title_font);
    padding: 30px 0;
    font-size: 80px;
    border-top: 8px solid var(--porter_black);
    border-bottom: 5px solid var(--porter_black);
}

.homeSectionsContainer{
    background-color: var(--ebb_white);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 50px 5%;
}

@media screen and (max-width: 1581px) {
	.introTitle {
		font-size: 65px;
	}
}

@media screen and (max-width: 455px){
	.introTitle {
		font-size: 45px;
	}
}