.contactMe {
	height: calc(100vh - 225px); 
	background-color: var(--ebb_white);
	padding: 50px 5%;
}
.contactMe h2 {
	color: var(--porter_black);
	font-family: var(--title_font);
	font-size: 75px;
	border-top: 8px solid var(--porter_black);
	font-weight: 900;
	padding-top: 20px;
	margin-bottom: 20px;
}
.contactMe hr{
	width: 60%;
	color: var(--porter_black);
	height: 5px;
	background-color: var(--porter_black);
}
.contactMeContainer {
	display: flex;
	flex-wrap: wrap;
}
.contactBox {
	width: 60%;
	padding-top: 20px;
}
.contactBox div {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
.input {
	background-color: white;
	padding: 10px;
	margin: 20px;
	width: 40%;
	font-size: 20px;
}
.input:focus {
	outline: 2px solid var(--red_orange);
}
.inputTextArea {
	background-color: white;
	width: 84%;
	margin: 20px 86px;
	height: 100px;
	padding: 20px;
	font-size: 20px;
}
.inputTextArea:focus {
	outline: 2px solid var(--red_orange);
}
.contactBox button {
	display: block;
	padding: 10px 40px;
	margin: 0 auto;
	font-size: 22px;
	font-weight: 700;
	background-color: var(--porter_black);
	color: var(--ebb_white);
	border-radius: 5px;
}
.contactBox button:hover {
	background-color: var(--red_orange);
	color: var(--porter_black);
}
.imgContactInfoContainer {
	width: 35%;
	margin: auto;
}
.imgContactInfoContainer img {
	width: 100%;
}
.contactErrorMessage {
	margin: 20px;
	font-size: 20px;
	color: var(--red_orange);
}

@media screen and (max-width: 1800px){
	.contactMe {
		height: 100%;
	}
}
@media screen and (max-width: 1660px) {
	.contactMe {
		width: 100vw;
		height: 100%;
		background-color: var(--ebb_white);
		padding-top: 40px;
	}
 .contactBox{
	width: 100vw;
	display: flex;
	margin: 0 5%;
	flex-direction: column;
	justify-content: center;
 }
 .imgContactInfoContainer {
	width: 100%;
	min-width: 300px;
	max-width: 1600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 30px;
}
.contactBox button {
	display: block;
	padding: 10px 40px;
	margin: 0 auto 30px;
	background-color: var(--porter_black);
	color: var(--ebb_white);
	border-radius: 5px;
}
.businessContactInfo {
	width: 100%;
	color: var(--ebb_white);
}
.businessContactInfo span{
	color: var(--ebb_white);
}
.inputTextArea {
	width: 90%;
	margin: 20px 5%;
}
}

@media screen and (max-width: 490px){
	.inputTextArea {
		width: 90%;
		margin: 20px;
	}
	.contactMe h2 {
		font-size: 45px;
	}
}
@media screen and (max-width: 445px){
	.input {
		width: 100%;
	}

}