.headerContainer {
	background-color: var(--porter_black);
	display: flex;
	justify-content: space-between;
	padding: 30px 0;
}

/*********** Logo Image **************/
.logoLink{
	margin-left: 5%;
}
.logoImg {
	height: 65px;
}
.logoVid{
	margin: -5% -2% 0 0;
	height: 85px;
	border: solid 1px var(--porter_black);
}
.logoVid video{
	height: 100%;
	object-fit: fill;
}
.displayNone{
	display:none;
}

/*********** Navigation Bar **************/
.navContainer {
	display: flex;
	margin-right: 5%;
	align-items: flex-end;
}
.navbarNav {
	display: flex;
}
.navLink {
	color: var(--ebb_white);
	margin: 0px 10px;
	font-size: 28px;
}
.navLink:hover {
	color: var(--red_orange);
}
.hamburger {
	display: none;
	cursor: pointer;
}
.bar {
	display: block;
	width: 25px;
	height: 3px;
	margin: 7px auto;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background-color: var(--ebb_white);
}

@media screen and (max-width: 971px) {
	.logoImg {
		padding-top: 30px;
		padding: 5px 0;
	}
	.navContainer {
		margin: auto 5%;
		background-color: var(--porter_black);
	}
	.hamburger {
		display: block;
		margin-top: 25px;
	}
	.hamburger.active .bar:nth-child(2) {
		opacity: 0;
	}
	.hamburger.active .bar:nth-child(1) {
		transform: translateY(10px) rotate(45deg);
	}
	.hamburger.active .bar:nth-child(3) {
		transform: translateY(-10px) rotate(-45deg);
	}
	.navbarNav {
		display: none;
	}
	.navbarDropdown {
		display: flex;
		position: fixed;
		z-index: 3;
		right: 5%;
		margin-top: 15px;
		flex-direction: column;
		text-align: center;
		border: solid 1px var(--ebb_white);
		background-color: var(--porter_black);
	}
	.navbarDropdown a {
		/* margin: 3px; */
		font-size: 25px;
		/* border-radius: 5px; */
		padding: 15px 25px;
		background-color: var(--porter_black);
		/* border: solid 1px var(--ebb_white); */
	}
}
@media screen and (max-width: 500px) {
	.logoImg {
		height: 50px;
	}
}